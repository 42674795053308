import { Container, Stack, Box, Typography, Button } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import ProductTable from "../../Organisms/Products/Table";
import ProductDetailCards from "../../Organisms/Products/DetailCards";
import ProductDetailDescription from "../../Organisms/Products/DetailDescription";
import productDetailsVideo from "../../Assets/Images/productDetailsVideo.png";
import playIcon from "../../Assets/Images/PlayIcon.svg";
import arrowUp from "../../Assets/Images/arrowUp.png";
import { useThemePalette } from "../../Theme/useThemePalatte";
import SectionTitle from "../../Atoms/SectionTitle";
import productDetailsBg from "../../Assets/Images/productDetailsBg.png";
import Motion from "../../Atoms/SharedComponent/Motion";
import { fadeInLeft, fadeInRight } from "../../Organisms/Home/MotionDirections";
import Loading from "../../Atoms/SharedComponent/Loading";
import YouTubeVideo from "../../Atoms/YouTubeVideo";
import Breadcrumb from "../../Atoms/Breadcrumb";
import { SamplePrevArrow } from "../../Molecules/SliderArrows";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomVideo from "../../Atoms/CustomVideo";

export default function ProductDetailTemplate({
  finalProduct,
  productDetailsPageData,
}) {
  const { blueThree, grayThree } = useThemePalette();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlayed, setIsPlayed] = useState({ state: false, id: null });
  const playVideo = (id) => {
    document.querySelector(`#${id}`)?.play();
  };
  const tableHead = [
    "",
    "Per 100g",
    "Per serving –90ml/67g",
    "% Reference  quantity*",
  ];
  const tableRowData = [
    {
      id: 5,
      name: "Energy",
      value_per_100g: 1,
      value_per_serving: "8.1",
      unit: "g",
      ref_quantity: 11,
    },
    {
      id: 5,
      name: "Fat",
      value_per_100g: 1,
      value_per_serving: 26,
      unit: "kcal",
      ref_quantity: 32,
    },
    {
      id: 5,
      name: "of which saturates",
      value_per_100g: 1,
      value_per_serving: 3.4,
      unit: "kcal",
      ref_quantity: 55,
    },
    {
      id: 5,
      name: "Carbohydrate",
      value_per_100g: 1,
      value_per_serving: 0.25,
      unit: "kcal",
      ref_quantity: 84,
    },
    {
      id: 5,
      name: "of which sugars",
      value_per_100g: 1,
      value_per_serving: 1.2,
      unit: "kcal",
      ref_quantity: 44,
    },
    {
      id: 5,
      name: "Dietary Fibre",
      value_per_100g: "21",
      value_per_serving: "-",
      unit: "gm",
      ref_quantity: 15,
    },
  ];

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/product", {
      state: { from: window.location.pathname },
    });
  };
  console.log(productDetailsPageData?.product_name);
  const breadcrumbItems = [
    productDetailsPageData?.product_name
      ? {
          label: `Products - ${productDetailsPageData?.product_name}`,
          link: "/product",
        }
      : null,
    {
      label: `${productDetailsPageData?.product_name} Details`,
    },
  ];
  return (
    <Suspense fallback={<Loading />}>
      <Box
        sx={{
          pt: { md: 15 },
        }}
      >
        <Container maxWidth="xl">
          <Breadcrumb onClick={handleBackClick} breadcrumbs={breadcrumbItems} />
          <ProductDetailDescription
            productDetailsPageData={productDetailsPageData}
            finalProduct={finalProduct}
          />
          <Stack
            sx={{ my: "50px" }}
            justifyContent="center"
            alignItems="center"
          >
            {productDetailsPageData?.video_url && (
              <CustomVideo
                videoUrl={productDetailsPageData?.video_url}
                width="65%"
              />
            )}
          </Stack>

          {/* <CustomVideo
            videoUrl="https://www.youtube.com/watch?v=tQLgyUKYFfE"
            width="60%"
          /> */}
          <Stack my="80px" pt="3rem">
            <Stack>
              <Motion {...fadeInLeft({ width: "100%", duration: "1.9" })}>
                <Stack direction="row" alignItems="center" mb="32px">
                  <SectionTitle
                    fontSize={{ md: "40px", xs: "20px" }}
                    boxShadow="none"
                  >
                    NUTRITION & INGREDIENTS
                  </SectionTitle>
                  <Box
                    sx={{
                      pl: "24px",
                      cursor: "pointer",
                      transform: isExpanded
                        ? ""
                        : "translate(30%, 0%) rotate(180deg)",
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                    width={{ md: "46px", xs: "25px" }}
                  >
                    <img src={arrowUp} width="100%" alt="" />
                  </Box>
                </Stack>
              </Motion>
            </Stack>

            <Stack
              direction={{ md: "row" }}
              display={isExpanded ? "none" : ""}
              justifyContent="space-between"
            >
              <Stack width={{ md: "53%", xs: "99%" }}>
                <Motion {...fadeInLeft({ width: "100%", duration: "1.9" })}>
                  <Stack width={{ md: "100%", xs: "99%" }}>
                    <SectionTitle
                      fontSize={{ lg: "35px", md: "30px", xs: "18px" }}
                      color={blueThree}
                      boxShadow="none"
                      pt="16px"
                      pb="32px"
                    >
                      Nutritional Information
                    </SectionTitle>
                    <Box sx={{ md: "" }}>
                      <ProductTable
                        tableRow={finalProduct?.tableRow}
                        tableRowData={tableRowData}
                        tableHead={tableHead}
                      />
                    </Box>
                  </Stack>
                </Motion>
              </Stack>
              <Stack width={{ md: "45%", xs: "99%" }}>
                <Motion {...fadeInRight({ width: "100%", duration: "1.9" })}>
                  <Stack width={{ md: "100%", xs: "99%" }}>
                    <SectionTitle
                      fontSize={{ lg: "35px", md: "30px", xs: "18px" }}
                      color={blueThree}
                      boxShadow="none"
                      pt={{ md: "16px", xs: "32px" }}
                      pb="32px"
                    >
                      ingredients
                    </SectionTitle>
                    {productDetailsPageData?.ingredients_description && (
                      <Typography
                        sx={{
                          color: grayThree,
                          fontFamily: "Inter",
                          width: "100%",
                          fontSize: { lg: "22px", md: "15px", xs: "12px" },
                          fontWeight: 500,
                          mb: "20px",
                          letterSpacing: "0.2px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            productDetailsPageData?.ingredients_description,
                        }}
                      />
                    )}
                  </Stack>
                </Motion>
              </Stack>
            </Stack>
          </Stack>
          {productDetailsPageData?.related_products?.length >= 1 && (
            <Stack spacing={4} mt="5%">
              <Box sx={{ mb: "120px" }}>
                <SectionTitle
                  fontSize={{ md: "40px", xs: "20px" }}
                  boxShadow="none"
                >
                  Flavors & Related products
                </SectionTitle>
              </Box>

              <ProductDetailCards
                finalProduct={productDetailsPageData?.related_products}
              />
            </Stack>
          )}
        </Container>
      </Box>
    </Suspense>
  );
}
