import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import YouTubeVideo from "./YouTubeVideo";

const CustomVideo = ({ videoUrl, width = "100%", style }) => {
  // Check if the video URL is a YouTube link
  const isYouTubeLink = (url) => {
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };
  const [isMuted, setIsMuted] = useState(true); // Start with muted
  const [userInteracted, setUserInteracted] = useState(false); // Track user interaction

  const iframeRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { threshold: 0.2 }
      ),
    []
  );

  useEffect(() => {
    observer.observe(iframeRef.current);
    return () => {
      observer.disconnect();
    };
  }, [iframeRef, observer]);

  const lastScrollYRef = useRef(0);

  useEffect(() => {
    if (iframeRef?.current?.tagName === "IFRAME") {
      if (isIntersecting) {
        setIsMuted(false); // Unmute when visible
      } else {
        setIsMuted(true); // Mute when not visible
      }
    }
  }, [isIntersecting, iframeRef.current]);

  const handleScroll = () => {
    if (userInteracted && iframeRef?.current?.tagName !== "IFRAME") {
      if (isIntersecting) {
        setIsMuted(false);
      } else {
        setIsMuted(true);
      }
    }
  };

  useEffect(() => {
    lastScrollYRef.current = window.scrollY;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isIntersecting]);

  const handleUserInteraction = (value) => {
    setIsMuted(value);
    setUserInteracted(true);
  };
  return (
    <>
      {isYouTubeLink(videoUrl) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <YouTubeVideo
            videoId={YouTubeVideoId(videoUrl)}
            width={width}
            iframeRef={iframeRef}
            isMuted={isMuted}
          />
        </div>
      ) : (
        <Stack
          sx={{ position: "relative", width: width || "100%", height: "100%" }}
          alignItems='center'
          justifyContent='center'
        >
          <Box>
            {isMuted && (
              <Stack
                sx={{
                  position: "absolute",
                  top: "10px",
                  cursor: "pointer",
                  zIndex: 9,
                  left:'0'
                }}
                onClick={(_) => handleUserInteraction(false)}
              >
                <VolumeOffIcon sx={{ fontSize: "60px", color: "white" }} />
              </Stack>
            )}
          </Box>
            <video
              ref={iframeRef}
              id="custom-video"
              width={width}
              muted={isMuted}
              autoPlay
              loop
              style={style}
            >
              <source src={videoUrl} />
            </video>
        </Stack>
      )}
    </>
  );
};

// Utility function to extract the YouTube video ID from the URL
const YouTubeVideoId = (url) => {
  const urlObj = new URL(url);
  if (urlObj.hostname === "youtu.be") {
    return urlObj.pathname.slice(1);
  }
  return urlObj.searchParams.get("v");
};

export default CustomVideo;
